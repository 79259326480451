body {
  font-family: 'Be Vietnam Pro', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
  padding: 0;
  color: #717171;
  overflow-x: hidden;
  overflow-y: auto;
}

*{
  box-sizing: border-box;
}

a, a:hover{
  text-decoration: none;
}

h1{
  /* margin: 0; */
  font-size: 45px;
  font-weight: bold;
  color: #00141f;
  line-height: 50px;
}

h2{
  /* margin: 0; */
  font-size: 45px;
  font-weight: bold;
  color: #00141f;
  line-height: 50px;
}

h3{
  /* margin: 0; */
  font-size: 25px;
  font-weight: bold;
  color: #00141f;
}

@media (max-width: 991px){
  h1{
    font-size: 35px;
  }
  h2{
    font-size: 35px;
  }
  h3{
    font-size: 20px;
  }
}

h1:first-child,
h2:first-child{
  margin-top: 0;
}

.underline_silvertree{
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
  text-decoration-style: solid;
  -webkit-text-decoration-thickness: 6px;
  text-decoration-thickness: 6px;
  -webkit-text-decoration-color: #76DA9F;
  text-decoration-color: #76DA9F;
}

.underline_chetwodeblue{
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
  text-decoration-style: solid;
  -webkit-text-decoration-thickness: 6px;
  text-decoration-thickness: 6px;
  -webkit-text-decoration-color: #9DACF0;
  text-decoration-color: #9DACF0;
}


#iubenda-cs-banner {
  bottom: 0px !important;
  left: 0px !important;
  position: fixed !important;
  width: 100% !important;
  z-index: 99999998 !important;
  background-color: black;
}
.iubenda-cs-content {
  display: block;
  margin: 0 auto;
  padding: 20px;
  width: auto;

  font-size: 14px;
  background: #000;
  color: #fff;
}
.iubenda-cs-rationale {
  max-width: 1140px;
  position: relative;
  margin: 0 auto;
}
.iubenda-banner-content > p {
  line-height: 1.5;
}
.iubenda-cs-close-btn {
  margin:0;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
}
.iubenda-cs-cookie-policy-lnk {
  text-decoration: underline;
  color: #fff;
  font-size: 14px;
  font-weight: 900;
}
